<template lang="html">
  <div class="">
    <img src="@/assets/logonewest.svg" alt="" id="logo">
  </div>
<div class="opis">


  <h1>Istraživanje stavova zaposlenih</h1>
  <p>Poštovani/a,</p>
  <p>Učestvujete u istraživanju stavova zaposlenih.
Pred Vama se nalazi upitnik koji je namenjen ispitivanju stavova zaposlenih sa ciljem unapređenja poslovanja i radne atmosfere u Kompaniji.
</p>
<p>Upitnik sadrži niz pitanja sa ponuđenim odgovorima, a obeležavanjem kućice ispred odgovora se opredeljujete za jedan od njih, koji najpribližnije odražava Vaš lični stav o tvrdnji iznetoj u pitanju ili Vašu ocenu o nivou ispunjenosti nekog elementa. Potrebno je odabrati samo jedan od ponuđenih odgovora za svako pitanje (osim tamo gde je naznačeno da je potrebno više odgovora). Ovo je jedna od prilika da date svoj doprinos u ostvarivanju navedenog cilja koji je i u Vašem interesu, pa je veoma važno da na sva pitanja odgovorite što iskrenije</p>
<p>Vaše učešće u istraživanju je anonimno.</p>
<h2 class="vreme">Vreme: {{Math.floor(timerCount/3600)}}:{{Math.floor(timerCount/60)%60}}:{{timerCount%60}}</h2>

  </div>
  <div class="row">
    <div class="card mx-auto">
  <div class="card-body">

  <form @submit.prevent="submitStavoviZaposlenihThis">


      <div class="form-group">
        <h4> Staž u kompaniji</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="staza" id="staza" v-model="staz" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Manje od 6 meseci
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="stazb" id="stazb" v-model="staz" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Između 6 meseci i 1 godine
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="stazc" id="stazc" v-model="staz" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            između 1 godine i 2 godine
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="stazd" id="stazd" v-model="staz" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            više od 2 godine
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4> Pol</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="pola" id="pola" v-model="pol" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Muski
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="polb" id="polb" v-model="pol" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Zenski
          </label>
        </div>
      </div>

      <div class="headerOpis">
        <h2>Korporativna Kultura</h2>
        <p>Kompanijske vrednosti - Set principa, verovanja, postulata koji utiču na to kako se mi kao pojedinci i kao grupa ponašamo, kako unutar naše organizacije, tako i u odnosu sa okruženjem</p>

      </div>

      <div class="form-group">
        <h4>1. Da li smatrate da su Vam kompanijske vrednosti potrebne? </h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans11a" id="ans11a" v-model="ans11" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans11a" id="ans11b" v-model="ans11" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>

      </div>

      <div class="form-group">
        <h4>2. Ukoliko ste na prethodno pitanje odgovorili sa DA, navedite koje kompanijske vrednosti prema Vašem mišljenju treba da poseduje kompanija u kojoj radite:</h4>
        <input
          class="form-control"
          type="text"
          placeholder=""
          name="ans12"
          v-model="ans12"
          id="ans12"
          >
      </div>

      <div class="form-group">
        <h4>3. Da li kompanija poseduje paket benefita za zaposlene? </h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans13a" id="ans13a" v-model="ans13" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans13b" id="ans13b" v-model="ans13" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>4. Obeležite 2 beneficije koje biste želeli da Vam kompanija u budućem periodu obezbedi: </h4>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" name="ans14a" id="ans14a" v-model="ans141" value="1">
          <label class="form-check-label" for="ans14a">
          Dodatni slobodni dani
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" name="ans14b" id="ans14b" v-model="ans142" value="1">
          <label class="form-check-label" for="ans14b">
            Teambuilding aktivnosti
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" name="ans14c" id="ans14c" v-model="ans143" value="1">
          <label class="form-check-label" for="ans14c">
            Članstvo u fitness centrima
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" name="ans14d" id="ans14d" v-model="ans144" value="1">
          <label class="form-check-label" for="ans14d">
            Privatno zdravstveno osiguranje
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" name="ans14e" id="ans14e" v-model="ans145" value="1">
          <label class="form-check-label" for="ans14e">
            Dodatno stručno usavršavanje
          </label>
        </div>
      </div>

      <div class="headerOpis">
        <h2>Međuljudski odnosi</h2>

      </div>


      <div class="form-group">
        <h4>1. U kojoj meri je prisutan timski rad u kompaniji? </h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans21a" id="ans21a" v-model="ans21" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
            Prisutan
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans21b" id="ans21b" v-model="ans21" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Delimično prisutan
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans21b" id="ans21b" v-model="ans21" value="3">
          <label class="form-check-label" for="flexRadioDefault2">
            Nije prisutan
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>2. Da li imate prijatelje među kolegama?</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans22a" id="ans22a" v-model="ans22" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans22b" id="ans22b" v-model="ans22" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>3.	Zadovoljan/na sam komunikacijom sa mojim pretpostavljenim.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans23a" id="ans23a" v-model="ans23" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans23b" id="ans23b" v-model="ans23" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>4.	Zadovoljan/na sam komunikacijom sa kolegama.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans24a" id="ans24a" v-model="ans24" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans24b" id="ans24b" v-model="ans24" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>5.	Zaposleni imaju osećaj zajedništva i međuzavisnosti.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans25a" id="ans25a" v-model="ans25" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans25b" id="ans25b" v-model="ans25" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>6. Kako biste ocenili radnu atmosferu?</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans26a" id="ans26a" v-model="ans26" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
            Veoma prijatna
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans26b" id="ans26b" v-model="ans26" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Prijatna
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans26c" id="ans26c" v-model="ans26" value="3">
          <label class="form-check-label" for="flexRadioDefault2">
            Neprijatna
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans26d" id="ans26d" v-model="ans26" value="4">
          <label class="form-check-label" for="flexRadioDefault2">
            Veoma neprijatna
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>7. Da li se družite sa kolegama i van radnog vremena?</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans27a" id="ans27a" v-model="ans27" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans27b" id="ans27b" v-model="ans27" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>8. Da li se često javljaju konflikti među zaposlenima?</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans28a" id="ans28a" v-model="ans28" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans28b" id="ans28b" v-model="ans28" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>



      <div class="headerOpis">
      <h2>Korporativna komunikacija</h2>
      </div>

      <div class="form-group">
        <h4>1.	Da li ste upoznati sa poslovnim rezultatima kompanije? </h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans31a" id="ans31a" v-model="ans31" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans31b" id="ans31b" v-model="ans31" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>2.	Da li ste upoznati sa radom drugih kolega? </h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans32a" id="ans32a" v-model="ans32" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans32b" id="ans32b" v-model="ans32" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>3.	Da li smatrate da su Vam sve neophodne informacije u vezi kompanije na raspolaganju?</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans33a" id="ans33a" v-model="ans33" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans33b" id="ans33b" v-model="ans33" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>4.	Da li se zaposleni u kompaniji pitaju za mišljenje?</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans34a" id="ans34a" v-model="ans34" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans34b" id="ans34b" v-model="ans34" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>5. Da li zaposleni imaju uticaja na odluke koje se donose?</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans35a" id="ans35a" v-model="ans35" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans35b" id="ans35b" v-model="ans35" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>6. Da li znate strategiju kompanije?</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans36a" id="ans36a" v-model="ans36" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans36b" id="ans36b" v-model="ans36" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>7. Da li se poštuju Zakonom zagarantovana prava zaposlenih?</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans37a" id="ans37a" v-model="ans37" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans37b" id="ans37b" v-model="ans37" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>8. Da li ste upoznati sa strategijom Vaše organizacione celine?</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans38a" id="ans38a" v-model="ans38" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans38b" id="ans38b" v-model="ans38" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>9. Da li ste upoznati sa radom drugih organizacionih celina?</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans39a" id="ans39a" v-model="ans39" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans39b" id="ans39b" v-model="ans39" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>10.  U kompaniji se ne zna ko šta radi.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans310a" id="ans310a" v-model="ans310" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans310b" id="ans310b" v-model="ans310" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>11.  Zaposleni vode računa o ugledu kompanije.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans311a" id="ans311a" v-model="ans311" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans311b" id="ans311b" v-model="ans311" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>



      <div class="headerOpis">
      <h2>Organizacija rada</h2>
    </div>

      <div class="form-group">
        <h4>1. Zaposlenima su obezbeđeni dobri radni uslovi.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans41a" id="ans41a" v-model="ans41" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans41b" id="ans41b" v-model="ans41" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>2. Zaposleni koriste savremene uređaje i opremu.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans42a" id="ans42a" v-model="ans42" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans42b" id="ans42b" v-model="ans42" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>3. Da li ste zadovoljni radnim prostorom u kom obavljate posao?</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans43a" id="ans43a" v-model="ans43" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans43b" id="ans43b" v-model="ans43" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>4. Zaposlenima je obezbeđen parking prostor.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans44a" id="ans44a" v-model="ans44" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans44b" id="ans44b" v-model="ans44" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>5. Posao je dobro organizovan.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans45a" id="ans45a" v-model="ans45" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans45b" id="ans45b" v-model="ans45" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>6. Broj zaposlenih je usklađen sa obimom posla.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans46a" id="ans46a" v-model="ans46" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans46b" id="ans46b" v-model="ans46" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>7. Zaposleni su posvećeni svom poslu.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans47a" id="ans47a" v-model="ans47" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans47b" id="ans47b" v-model="ans47" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>8. Tačno znam koji su moji radni zadaci. </h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans48a" id="ans48a" v-model="ans48" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans48b" id="ans48b" v-model="ans48" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>9. Tačno znam koji su mi zadaci prioritetni.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans49a" id="ans49a" v-model="ans49" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans49b" id="ans49b" v-model="ans49" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>10. Od nadređenog dobijam jasne instrukcije u vezi zadataka. </h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans410a" id="ans410a" v-model="ans410" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans410b" id="ans410b" v-model="ans410" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>11. Smatram da imam podršku od strane nadređenog u izvršavanju zadataka.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans411a" id="ans411a" v-model="ans411" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans411b" id="ans411b" v-model="ans411" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>12. Svoje radne zadatke uspevam da završim u okviru radnog vremena. </h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans412a" id="ans412a" v-model="ans412" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans412b" id="ans412b" v-model="ans412" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>13. Ostajem prekovremeno na poslu.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans413a" id="ans413a" v-model="ans413" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans413b" id="ans413b" v-model="ans413" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>14. Ako ostajem prekovremeno na poslu, ostajem da bih:</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans414a" id="ans414a" v-model="ans414" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
            Stigao-la da obavim sve zadatke
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans414b" id="ans414b" v-model="ans414" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Da bih postigao-la bolje rezultate
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans414c" id="ans414c" v-model="ans414" value="3">
          <label class="form-check-label" for="flexRadioDefault2">
            Jer se to očekuje od zaposlenih
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans414d" id="ans414d" v-model="ans414" value="4">
          <label class="form-check-label" for="flexRadioDefault2">
            Bez odgovora
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>15. Zaposleni uspevaju da usklade poslovni i privatni život.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans415a" id="ans415a" v-model="ans415" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans415b" id="ans415b" v-model="ans415" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>16.  Zaposleni su blagovremeno informisani o odlukama koje utiču na njihov rad.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans416a" id="ans416a" v-model="ans416" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans416b" id="ans416b" v-model="ans416" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>



      <div class="headerOpis">
      <h2>Razvojne mogućnosti</h2>
      </div>



      <div class="form-group">
        <h4>1.	Razmišljam da odem iz kompanije.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans51a" id="ans51a" v-model="ans51" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans51b" id="ans51b" v-model="ans51" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>


      <div class="form-group">
        <h4>2.	Očekujem da će mi primanja rasti. </h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans52a" id="ans52a" v-model="ans52" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans52b" id="ans52b" v-model="ans52" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>3.	Tačno znam šta treba da radim da bi napredovao/la u kompaniji.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans53a" id="ans53a" v-model="ans53" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans53b" id="ans53b" v-model="ans53" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>4.	Kompanija mi omogućava stručno usavršavanje. </h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans54a" id="ans54a" v-model="ans54" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans54b" id="ans54b" v-model="ans54" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>5.	Zaposleni stiču dragoceno iskustvo radeći za kompaniju.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans55a" id="ans55a" v-model="ans55" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans55b" id="ans55b" v-model="ans55" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>6.	Priroda posla je takva da angažuje sposobnosti i znanja zaposlenih.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans56a" id="ans56a" v-model="ans56" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans56b" id="ans56b" v-model="ans56" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>



      <div class="headerOpis">
      <h2>Zadovoljstvo radnim mestom</h2>
      </div>



      <div class="form-group">
        <h4>1.	Volim svoj posao.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans61a" id="ans61a" v-model="ans61" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans61b" id="ans61b" v-model="ans61" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>2.	Moje radno mesto je važno za uspeh kompanije.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans62a" id="ans62a" v-model="ans62" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans62b" id="ans62b" v-model="ans62" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>3.	Svi zaposleni imaju isti tretman.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans63a" id="ans63a" v-model="ans63" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans63b" id="ans63b" v-model="ans63" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>4.	Imam svu neophodnu opremu za rad.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans64a" id="ans64a" v-model="ans64" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans64b" id="ans64b" v-model="ans64" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>5.	Zadovoljan/na sam radnim prostorom u kojem radim.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans65a" id="ans65a" v-model="ans65" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans65b" id="ans65b" v-model="ans65" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>6. Moj posao je monoton.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans66a" id="ans66a" v-model="ans66" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans66b" id="ans66b" v-model="ans66" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>7.	U kompaniji za koju radim vidim svoju dugoročnu perspektivu.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans67a" id="ans67a" v-model="ans67" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans67b" id="ans67b" v-model="ans67" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>8.	Da li je kompanija u kojoj ste zaposleni prepoznata na tržištu rada kao poželjan Poslodavac?</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans68a" id="ans68a" v-model="ans68" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans68b" id="ans68b" v-model="ans68" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>




      <div class="headerOpis">
      <h2>Zadovoljstvo materijalnim aspektom</h2>
</div>


      <div class="form-group">
        <h4>1.	Sistem nagrađivanja u kompaniji je adekvatan.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans71a" id="ans71a" v-model="ans71" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans71b" id="ans71b" v-model="ans71" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>

      <div class="form-group">
        <h4>2.	Moje radno mesto je vrednovano na pravi način.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans72a" id="ans72a" v-model="ans72" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans72b" id="ans72b" v-model="ans72" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>


      <div class="form-group">
        <h4>3.	Koji vid lične stimulacije po Vašem mišljenju ima najviše efekta? (Obeležiti samo jedan odgovor)</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans73a" id="ans73a" v-model="ans73" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
            Mesečna stimulacija
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans73b" id="ans73b" v-model="ans73" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Kvartalni bonus
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans73c" id="ans73c" v-model="ans73" value="3">
          <label class="form-check-label" for="flexRadioDefault2">
            Godišnji bonus
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans73d" id="ans73d" v-model="ans73" value="4">
          <label class="form-check-label" for="flexRadioDefault2">
            Pohvala i javno priznanje
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans73e" id="ans73e" v-model="ans73" value="5">
          <label class="form-check-label" for="flexRadioDefault2">
            Nemam definisan stav o tome
          </label>
        </div>
      </div>


      <div class="form-group">
        <h4>4.	Da li smatrate da se Vaš rad, učinak i zalaganje prate i vrednuju objektivno i pravedno?</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans74a" id="ans74a" v-model="ans74" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans74b" id="ans74b" v-model="ans74" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>


      <div class="headerOpis">
      <h2>Lojalnost zaposlenih</h2>
  </div>


      <div class="form-group">
        <h4>1.	Rangirajte (poređajte po važnosti ocenom od 1 (najmanje važno) do 5 (najvažnije)) značaj sledećih faktora kada je u pitanju posao (visina plate, dobra organizacija, sigurnost, međuljudski odnosi, mogućnost napredovanja):</h4>
        <div class="form-check">
          <label class="form-check-label" for="ans81a">
            Visina plate
          </label>
          <select name="collegue" id="collegue" v-model="ans811">
             <option  v-for="podr in answers1to5" v-bind:key="podr">{{podr}}</option>
          </select>
        </div>
        <div class="form-check">
          <label class="form-check-label" for="ans81b">
            Dobra organizacija
          </label>
          <select name="collegue" id="collegue" v-model="ans812">
             <option  v-for="podr in answers1to5" v-bind:key="podr">{{podr}}</option>
          </select>
        </div>
        <div class="form-check">
          <label class="form-check-label" for="ans81c">
          Sigurnost
          </label>
          <select name="collegue" id="collegue" v-model="ans813">
             <option  v-for="podr in answers1to5" v-bind:key="podr">{{podr}}</option>
          </select>
        </div>
        <div class="form-check">
          <label class="form-check-label" for="ans81d">
          Međuljudski odnosi
          </label>
          <select name="collegue" id="collegue" v-model="ans814">
             <option  v-for="podr in answers1to5" v-bind:key="podr">{{podr}}</option>
          </select>
        </div>
        <div class="form-check">
          <label class="form-check-label" for="ans81e">
          Mogućnost napredovanja
          </label>
          <select name="collegue" id="collegue" v-model="ans815">
             <option  v-for="podr in answers1to5" v-bind:key="podr">{{podr}}</option>
          </select>
        </div>
      </div>





      <div class="form-group">
        <h4>2.	 Zaposleni ne strahuju za sigurnost svog radnog mesta.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans82a" id="ans82a" v-model="ans82" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans82b" id="ans82b" v-model="ans82" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>


      <div class="form-group">
        <h4>3.	 Zaposleni veruju da su oni koji su napustili kompaniju pogrešili.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans83a" id="ans83a" v-model="ans83" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans83b" id="ans83b" v-model="ans83" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>


      <div class="form-group">
        <h4>4.	 Zaposleni bi preporučili svoju kompaniju onima koji traže posao.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans84a" id="ans84a" v-model="ans84" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans84b" id="ans84b" v-model="ans84" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>


      <div class="form-group">
        <h4>5.	Smatram da je broj ljudi koji napušta kompaniju zanemarljiv.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans85a" id="ans85a" v-model="ans85" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans85b" id="ans85b" v-model="ans85" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>


      <div class="form-group">
        <h4>6.	Smatram da većina kolega ne planira promenu radnog mesta.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans86a" id="ans86a" v-model="ans86" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans86b" id="ans86b" v-model="ans86" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>


      <div class="form-group">
        <h4>7.	Većina kolega ima poverenje u rukovodstvo kompanije.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans87a" id="ans87a" v-model="ans87" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
            Tačno
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans87b" id="ans87b" v-model="ans87" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Delimično tačno
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans87c" id="ans87c" v-model="ans87" value="3">
          <label class="form-check-label" for="flexRadioDefault2">
            Nije tačno
          </label>
        </div>
      </div>


      <div class="headerOpis">
      <h2>Angažovanje zaposlenih u proces poslovanja</h2>
  </div>

      <div class="form-group">
        <h4>1.	Zaposleni se ohrabruju da daju nove ideje i predloge.</h4>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans91a" id="ans91a" v-model="ans91" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          DA
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans91b" id="ans91b" v-model="ans91" value="0">
          <label class="form-check-label" for="flexRadioDefault2">
            NE
          </label>
        </div>
      </div>


      <div class="form-group">
        <h4>2.	Navedite Vaše predloge i ideje za poboljšanje efikasnosti poslovanja i bolje organizacije?</h4>
        <div class="form-check">
          <label class="form-check-label" for="ans92a">
          Komentar:
          </label>
          <input class="form-input" type="text" name="ans92a" id="ans92a" v-model="ans92" >

        </div>
      </div>





    <button class="btn btn-primary">Submit</button>

  </form>
  </div>
    </div>
    </div>


</template>

<script>
import {mapActions,mapGetters} from 'vuex';

export default {
  computed: mapGetters(['user']),
  data(){
    return{
      timerCount:4500,
      name:"",
      staz:"1",
      pol:"1",
      ans11:"1",
      ans12:"",
      ans13:"1",
      ans14:"1",
      ans21:"1",
      ans22:"1",
      ans23:"1",
      ans24:"1",
      ans25:"1",
      ans26:"1",
      ans27:"1",
      ans28:"1",
      ans31:"1",
      ans32:"1",
      ans33:"1",
      ans34:"1",
      ans35:"1",
      ans36:"1",
      ans37:"1",
      ans38:"1",
      ans39:"1",
      ans310:"1",
      ans311:"1",
      ans41:"1",
      ans42:"1",
      ans43:"1",
      ans44:"1",
      ans45:"1",
      ans46:"1",
      ans47:"1",
      ans48:"1",
      ans49:"1",
      ans410:"1",
      ans411:"1",
      ans412:"1",
      ans413:"1",
      ans414:"1",
      ans415:"1",
      ans416:"1",
      ans51:"1",
      ans52:"1",
      ans53:"1",
      ans54:"1",
      ans55:"1",
      ans56:"1",
      ans61:"1",
      ans62:"1",
      ans63:"1",
      ans64:"1",
      ans65:"1",
      ans66:"1",
      ans67:"1",
      ans68:"1",
      ans71:"1",
      ans72:"1",
      ans73:"1",
      ans74:"1",
      ans811:1,
      ans812:2,
      ans813:3,
      ans814:4,
      ans815:5,
      ans82:"1",
      ans83:"1",
      ans84:"1",
      ans85:"1",
      ans86:"1",
      ans87:"1",
      ans91:"1",
      ans92:"",
      answers1to5:[1,2,3,4,5],
    };
  },
  methods:{
    ...mapActions(['submitStavoviZaposlenih','getProfile']),
    submitStavoviZaposlenihThis(){

      let staz ={
        a:0,
        b:0,
        c:0,
        d:0,
      };
      if(this.staz=='1') staz.a=1;
      if(this.staz=='2') staz.b=1;
      if(this.staz=='3') staz.c=1;
      if(this.staz=='4') staz.d=1;
      console.log(staz);
      let pol={
        a:0,
        b:0
      };
      if(this.pol=='1') pol.a=1;
      if(this.pol=='2') pol.b=1;
      // eslint-disable-next-line no-unused-vars
      let ans14={
        a:0,
        b:0,
        c:0,
        d:0,
        e:0
      };

      if(this.ans141) ans14.a=1;
      if(this.ans142) ans14.b=1;
      if(this.ans143) ans14.c=1;
      if(this.ans144) ans14.d=1;
      if(this.ans145) ans14.e=1;

      let ans21={
        a:0,
        b:0,
        c:0,
      }



      if(this.ans21==='1'){
      ans21.a=1;
      }
      if(this.ans21==='2'){
      ans21.b=1;
      }
      if(this.ans21==='3'){
      ans21.c=1;
      }

      let ans26={
        a:0,
        b:0,
        c:0,
        d:0,
      }
      if(this.ans26==='1'){
      ans26.a=1;
      }
      if(this.ans26==='2'){
      ans26.b=1;
      }
      if(this.ans26==='3'){
      ans26.c=1;
      }
      if(this.ans26==='4'){
      ans26.d=1;
      }


      ////

      let ans414={
        a:0,
        b:0,
        c:0,
        d:0,
      }
      if(this.ans414==='1'){
      ans414.a=1;
      }
      if(this.ans414==='2'){
      ans414.b=1;
      }
      if(this.ans414==='3'){
      ans414.c=1;
      }
      if(this.ans414==='4'){
      ans414.d=1;
      }

      ////

      let ans73={
        a:0,
        b:0,
        c:0,
        d:0,
        e:0
      }
      if(this.ans73==='1'){
      ans73.a=1;
      }
      if(this.ans73==='2'){
      ans73.b=1;
      }
      if(this.ans73==='3'){
      ans73.c=1;
      }
      if(this.ans73==='4'){
      ans73.d=1;
      }
      if(this.ans73==='5'){
      ans73.e=1;
      }

      //

      let ans81={
        a:this.ans811,
        b:this.ans812,
        c:this.ans813,
        d:this.ans814,
        e:this.ans815
      }
      ///

      let ans87={
        a:0,
        b:0,
        c:0,
      }
      if(this.ans87==='1'){
      ans87.a=1;
      }
      if(this.ans87==='2'){
      ans87.b=1;
      }
      if(this.ans87==='3'){
      ans87.c=1;
      }


    let stavoviZaposlenihData = {
      companyName:this.user.type,
      staz,
      pol,
      ans11:this.ans11,
      ans12:this.ans12,
      ans13:this.ans13,
      ans14,
      ans21,
      ans22:this.ans22,
      ans23:this.ans23,
      ans24:this.ans24,
      ans25:this.ans25,
      ans26,
      ans27:this.ans27,
      ans28:this.ans28,
      ans31:this.ans31,
      ans32:this.ans32,
      ans33:this.ans33,
      ans34:this.ans34,
      ans35:this.ans35,
      ans36:this.ans36,
      ans37:this.ans37,
      ans38:this.ans38,
      ans39:this.ans39,
      ans310:this.ans310,
      ans311:this.ans311,
      ans41:this.ans41,
      ans42:this.ans42,
      ans43:this.ans43,
      ans44:this.ans44,
      ans45:this.ans45,
      ans46:this.ans46,
      ans47:this.ans47,
      ans48:this.ans48,
      ans49:this.ans49,
      ans410:this.ans410,
      ans411:this.ans411,
      ans412:this.ans412,
      ans413:this.ans413,
      ans414,
      ans415:this.ans415,
      ans416:this.ans416,
      ans51:this.ans51,
      ans52:this.ans52,
      ans53:this.ans53,
      ans54:this.ans54,
      ans55:this.ans55,
      ans56:this.ans56,
      ans61:this.ans61,
      ans62:this.ans62,
      ans63:this.ans63,
      ans64:this.ans64,
      ans65:this.ans65,
      ans66:this.ans66,
      ans67:this.ans67,
      ans68:this.ans68,
      ans71:this.ans71,
      ans72:this.ans72,
      ans73,
      ans74:this.ans74,
      ans81,
      ans82:this.ans82,
      ans83:this.ans83,
      ans84:this.ans84,
      ans85:this.ans85,
      ans86:this.ans86,
      ans87,
      ans91:this.ans91,
      ans92:this.ans92,
      userId:this.user._id,
    };
    console.log(stavoviZaposlenihData);
    this.submitStavoviZaposlenih(stavoviZaposlenihData)
    .then(res=>{
      if(res.data.success){
        this.$router.push('/profile');
      }
    }).catch(err =>{
      console.log(err);
    });
    }
  },
  created(){
    this.getProfile();
  },
  watch: {
           timerCount: {
               handler(value) {
                   if (value > 0) {
                       setTimeout(() => {
                           this.timerCount--;
                       }, 1000);
                   }
               },
               immediate: true // This ensures the watcher is triggered upon creation
           }
       }
};
</script>

<style lang="css" scoped>
.opis{
  background-color: #bbffff;
  padding: 15px;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
}
.headerOpis{
  background-color: #bbffff;
  padding: 15px;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
}


.form-group{
  background-color: #bbffbb;
  padding: 15px;
}

.form-group:nth-child(1),
.form-group:nth-child(4),
.form-group:nth-child(6),
.form-group:nth-child(9),
.form-group:nth-child(11),
.form-group:nth-child(13),
.form-group:nth-child(15),
.form-group:nth-child(18),
.form-group:nth-child(20),
.form-group:nth-child(22),
.form-group:nth-child(24),
.form-group:nth-child(26),
.form-group:nth-child(28),
.form-group:nth-child(30),
.form-group:nth-child(32),
.form-group:nth-child(34),
.form-group:nth-child(36),
.form-group:nth-child(38),
.form-group:nth-child(40),
.form-group:nth-child(42),
.form-group:nth-child(44),
.form-group:nth-child(47),
.form-group:nth-child(49),
.form-group:nth-child(51),
.form-group:nth-child(54),
.form-group:nth-child(56),
.form-group:nth-child(58),
.form-group:nth-child(60),
.form-group:nth-child(63),
.form-group:nth-child(65),
.form-group:nth-child(68),
.form-group:nth-child(70),
.form-group:nth-child(72),
.form-group:nth-child(74),
.form-group:nth-child(76)
{
  background-color: #99dd99;
  padding: 15px;
}
.btn{
  width: 100%;
  background-color: #003366;
}
</style>
